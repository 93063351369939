import React from 'react'

const Footer = () => {
    return (
        <div>
            <div className="container">
                <div className='row'>
                <p className="footer-text">ELE Times © {new Date().getFullYear()}. All rights reserved.</p>
                </div></div>
        </div>
    )
}

export default Footer